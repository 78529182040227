
.programsWifiMenu {
    
    overflow: hidden;
    overflow-y: auto;
    padding-left: 0;
    padding-right: 0;
    border: 0.1rem solid "black";
    position: "absolute";
    z-index: 1999;
    width: 250px;
}

.programsWifiMenu .item {
    font-size: 20px;
    padding: 2px 0 2px 10px;
    font-weight: normal;
    font-family: "Segoe UI";
}

.programsWifiMenu .item:hover {
    background: #333;
}

.programsWifiMenu .item .fa {
    background: #999;
    padding: 5px;
}

.programsWifiMenu .item span {
    margin-left: 10px;
    font-size: 18px;
    line-height: 30px;
    vertical-align: bottom;
    font-weight: normal;
    font-family: "Segoe UI";
}
.programsWifiMenu .item p {
    margin-left: 10px;
    font-size: 12px;
    line-height: 30px;
    color:gray;
    vertical-align: bottom;
}


.programsWifiMenu button{
    background-color: rgb(0, 99, 177);
    border: none;
    margin-right: 20px;
    float: right;
    margin-bottom: 20px;
}