
.startmenu {
    overflow: hidden;
    opacity: 0;
    visibility: hidden;
    background: #171717;
    position: absolute;
    bottom: 50px;
    left: 0;
    height: 300px;
    width: 550px;
    padding-top: 5px;
    border-top: 1px solid rgba(255, 255, 255, 0.4);
    border-right: 1px solid rgba(255, 255, 255, 0.4);
}
.startmenu.open2 {
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    height: 400px;
    opacity: 1;
    visibility: visible;
}
.startmenu.open2 .icons .bottom {
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
    opacity: 1;
}
.startmenu.open2 .programs .item {
    margin-bottom: 1px;
    -webkit-transition: margin 0.5s ease-out;
    transition: margin 0.5s ease-out;
}
.startmenu.open2 .tiles .tile {
    margin-top: 0px;
    -webkit-transition: margin 0.5s ease-out;
    transition: margin 0.5s ease-out;
}
.startmenu .title {
    margin: 6px 0 0 0;
    padding: 8px 0 4px 10px;
}
.startmenu .title:hover {
    background: #333;
}
.startmenu > .container-fluid {
    padding: 0;
    height: 100%;
}
.startmenu > .container-fluid .row {
    margin-right: 0;
}
.startmenu > .container-fluid .row,
.startmenu > .container-fluid .programs,
.startmenu > .container-fluid .tiles {
    height: inherit;
    position: relative;
}
.startmenu .iconAppImage{
    height: 25px;
    width: 25px;
    background-color: blue;
}
.startmenu .icons {
    position: absolute;
    z-index: 500;
    top: 0;
    bottom: 0;
    left: 15px;
    width: 50px;
    overflow: hidden;
    -webkit-transition: all 0.1s ease-out;
    transition: all 0.1s ease-out;
}
.startmenu .icons.open2 {
    width: 200px;
    background: #333;
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
}
.startmenu .icons .icon {
    position: relative;
    line-height: 50px;
    width: 100%;
    text-align: left;
}

.startmenu .icons .icon .fa {
    margin-left: 17px;
}

.startmenu .icons .icon img {
    max-width: 25px;
    margin-left: -4px;
}

.startmenu .icons .icon > span {
    position: absolute;
    left: 60px;
    font-size: 0.6em;
    line-height: 50px;
    width: 100px;
    height: 50px;
    text-align: left;
}

.startmenu .icons .icon > span.start {
    text-transform: uppercase;
    font-size: 1em;
    line-height: 2.5em;
}

.startmenu .icons .icon.top {
    position: absolute;
    z-index: 100;
}

.startmenu .icons .bottom {
    position: relative;
    bottom: 0;
    height: 100%;
    opacity: 0;
}

.startmenu .icons .bottom .icon {
    position: relative;
    text-align: center;
}

.startmenu .icons .bottom .icon.power {
    bottom: 0;
}

.startmenu .icons .bottom .icon.settings {
    bottom: 50px;
}

.startmenu .icons .bottom .icon.file-explorer {
    bottom: 100px;
}

.startmenu .icons .bottom .icon.profile {
    bottom: 150px;
    padding: 0 17px;
}

.startmenu .icons .bottom .icon.profile img {
    display: inline;
    border-radius: 50%;
}

.startmenu .programs {
    overflow: hidden;
    overflow-y: auto;
    padding-left: 0;
    padding-right: 0;
}

.startmenu .programs .item {
    font-size: 20px;
    margin-bottom: 10px;
    padding: 2px 0 2px 10px;
}

.startmenu .programs .item:hover {
    background: #333;
}

.startmenu .programs .item .fa {
    background: #999;
    padding: 5px;
}

.startmenu .programs .item span {
    margin-left: 10px;
    font-size: 12px;
    line-height: 30px;
    vertical-align: bottom;
}

.startmenu .tiles {
    overflow: hidden;
    padding-right: 12px;
}

.startmenu .tiles:hover {
    overflow-y: auto;
    padding-right: 0;
}

.startmenu .tiles .title {
    padding-left: 0;
}

.startmenu .tiles .title:hover {
    background: transparent;
}

.startmenu .tiles .tile {
    position: relative;
    background: #333;
    padding: 20px;
    min-height: 105px;
    margin-bottom: 4px;
    margin-top: 30px;
    text-align: center;
}

.startmenu .tiles .iconSize-large{
    font-size: 3rem;
}
.startmenu .tiles .iconSize-medium{
    font-size: 2rem;
}

.startmenu .tiles .tile > span {
    position: absolute;
    bottom: 4px;
    left: 4px;
    right: 4px;
    text-align: left;
}

.startmenu .tiles .tile > span .fa {
    float: right;
    font-size: 14px;
}

.startmenu .tiles .tile.wide {
    width: 100%;
    clear: both;
}

.startmenu .tiles .tile.medium {
    width: 49%;
    float: left;
}

.startmenu .tiles .tile.medium:nth-child(odd) {
    margin-right: 4px;
}

.startmenu .tiles .tile.medium:nth-child(even) {
    float: right;
}