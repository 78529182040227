.desktopMockup {
    width: 100%;
    height: 86vh;
    background: url('../img/DesktopMockup.png') center top no-repeat;
    background-size: 100vw 100vh;
}

.insideMockup {
    position: absolute;
    left: 14.2%;
    top: 20.8%;
    width: 71.9%;
    height: 72%;
    overflow: hidden;
}

.progress_bar {
    position: relative;
    display: block;
    margin-top: 2vh;
    margin-left: auto;
    margin-right: auto;
    width: 60%;
    height: 3vh;
    background-color: ghostwhite;
}

.progress_validate{
    height: 100%;
    background: purple;
    padding-left: 2px;
    padding-right: 2px;
    background-clip: content-box;
    cursor : pointer;
}

.progress_non_validate{
    height: 100%;
    padding-left: 2px;
    padding-right: 2px;
    background-color: gainsboro;
    background-clip: content-box;
    cursor : pointer;
}

.help-button {
    position: absolute;
    bottom: 1em;
    right: 1em;
    text-align: center;
    height: 15em;
    width: 15em;
    font-size: 14px;
    cursor: pointer;
    transform: scale(1);
    transition: all 200ms ease;
    z-index: 99999;
  }

  .help-button-shake{
    animation: shake 0.5s;
    animation-iteration-count: infinite;
  }
  .help-button span {
    display: block;
    font-size: 2em;
    transform: scale(1);
    transition: transform 100ms ease;
  }
  .help-button:hover span,
  .expanded .help-button span,
  .expanded .help-button span {
    transform: scale(1.25);
  }

  .help-overlay {
      position: fixed;
      text-align: center;
      background: #fff;
      border-radius: 2px;
      bottom: 100px;
      right: 150px;
      width: 0%;
      height: 0%;
      opacity: 0.95;
      visibility: hidden;
      -webkit-transition: opacity .55s, height .35s, width .35s, visibility .35s;
      transition: opacity .55s, height .35s, width .35s, visibility .35s;
      overflow: auto;
        z-index: 9999;
  }
  
  .help-overlay.help-active {
    margin: auto; /* Important */ 
      opacity: .95;
      visibility: visible;
      height: 86%;
      width: 80%;
  }

  .speech-bubble-ds {
    background: #fff;
    border: 1px solid #a7a7a7;
    border-radius: 4px;
    box-shadow: 4px 4px 0 rgba(0, 0, 0, 0.2);
    font-size: 1.2rem;
    line-height: 1.3;
    margin: 0 auto 40px;
    padding: 15px;
  }
  
.leave-button {
    position: absolute;
    bottom: 1em;
    left: 1em;
    text-align: center;
    padding: 1%;
    font-size: 4vh;
    border-radius: 10%;
    border: 0 none;
    background: #C14F54;
    color: #fff;
    cursor: pointer;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    transform: scale(1);
    transition: all 200ms ease;
    font-family: 'Montserrat';
    font-weight: bold;
  }
  .leave-button span {
    display: block;
    font-size: 2em;
    transform: scale(1);
    transition: transform 100ms ease;
  }
  .leave-button:hover span,
  .expanded .leave-button span,
  .expanded .leave-button span {
    transform: scale(1.25);
  }

  .leave-overlay {
    position: fixed;
    text-align: center;
    vertical-align: middle;
    background: #fff;
    bottom: 50px;
    left: 50px;
    width: 0%;
    height: 0%;
    opacity: 0.95;
    visibility: hidden;
    -webkit-transition: opacity .55s, height .35s, width .35s, visibility .35s;
    transition: opacity .55s, height .35s, width .35s, visibility .35s;
    overflow: auto;
    z-index: 9999;
}

.leave-overlay.leave-active {
    opacity: .95;
    visibility: visible;
    height: 80%;
    width: 80%;
}



@keyframes shake {
  0% { transform: translate(0px, 0px) rotate(0deg); }
  10% { transform: translate(0px, 0px) rotate(0deg); }
  20% { transform: translate(0px, 0px) rotate(0deg); }
  30% { transform: translate(0px, 0px) rotate(0deg); }
  40% { transform: translate(0px, 0px) rotate(0deg); }
  50% { transform: translate(0px, 0px) rotate(0deg); }
  60% { transform: translate(0px, -30px) rotate(0deg); }
  70% { transform: translate(0px, -30px) rotate(0deg); }
  80% { transform: translate(0px, -30px) rotate(0deg); }
  90% { transform: translate(0px, -30px) rotate(0deg); }
  100% { transform: translate(0px, -30px) rotate(0deg); }
}

.displayNone{
  display : none;
}


.exercice-help{
  margin: auto; /* Important */ 
  text-align: center; 
  font-family: 'Montserrat';
  font-weight: bold;
  font-size: 5vh;
  color: #19355C;
}

.image-help{
  max-height: 50vh;
}