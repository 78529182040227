

div.window textarea{
    background-color: red;
}

.titleBarBtn {
    background: #0063b1;
    display: inline;
    float: right;
    width: 45px;
    height: 30px;
    font-size: 25px;
    font-family: 'Times New Roman', Times, serif;
    margin: auto auto;
    text-align: center;
    transition: 0.2s;
    border: none;
}

.titleBarBtn:hover{
    background: #01467a;
}

.closeBtn div{
}

.closeBtn:hover {
    background: red;
    color: white;
}

.titleBarBtn{
    padding-bottom: 30px;
}

div.window {
    border: 1px solid #0078D7;
    box-shadow: inset 0 0 0 1px rgba(246,246,247,0.92), 0 7px 19px rgba(0,0,0,0.9);
    padding: 0;
    position: relative;
    display: inline-block;
    overflow: hidden;
}

.iconContainer {
    display: inline;
    position: relative;
}


.titleFrame {
    position: relative;
    background: #0063B1;
    height: 30px;
    color:white;
}


.titleContent {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%)
}

.titleText {
    color: white;
    font-family: 'Segoe UI', Tahoma, sans-serif;
    font-weight: 400;
    font-size: 12px;
    display: inline;
    padding: 20px;
}

.titleText.noIcon {
    padding-left: 0;
}

.titleContent {
    padding: 6px 10px;
    display: inline;
}

.icon {
    position: absolute;
    top: 55%;
    transform: translate(0, -55%);
}