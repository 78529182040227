@import url(https://fonts.googleapis.com/css?family=Roboto:300,500);
@import url(https://fonts.googleapis.com/css?family=Roboto:300,500);
body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-size: cover;
    overflow-y: hidden;
    margin: 0 0;
    -moz-user-select: none;
     -ms-user-select: none;
         user-select: none;
    -webkit-user-select: none;
    overflow: hidden;
    background-color: white;
    height: 100vh;
}
#root{
    height: 100%;
}

/* remember to define focus styles! */
:focus {
    outline: 0;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
    width: 12px;
}
::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.2);
}
::-webkit-scrollbar-thumb {
    background: #c3c3c3;
}
::-webkit-scrollbar-button {
    background-size: 100%;
    height: 10px;
    width: 10px;
    -webkit-box-shadow: inset 1px 1px 2px rgba(0, 0, 0, 0.2);
}
div.desktop {
    width: 100%;
    height: 100%;
    background: url(/static/media/backgroundWindows.caafad9f.jpg) center top no-repeat;
    
}


div.window textarea{
    background-color: red;
}

.titleBarBtn {
    background: #0063b1;
    display: inline;
    float: right;
    width: 45px;
    height: 30px;
    font-size: 25px;
    font-family: 'Times New Roman', Times, serif;
    margin: auto auto;
    text-align: center;
    transition: 0.2s;
    border: none;
}

.titleBarBtn:hover{
    background: #01467a;
}

.closeBtn div{
}

.closeBtn:hover {
    background: red;
    color: white;
}

.titleBarBtn{
    padding-bottom: 30px;
}

div.window {
    border: 1px solid #0078D7;
    box-shadow: inset 0 0 0 1px rgba(246,246,247,0.92), 0 7px 19px rgba(0,0,0,0.9);
    padding: 0;
    position: relative;
    display: inline-block;
    overflow: hidden;
}

.iconContainer {
    display: inline;
    position: relative;
}


.titleFrame {
    position: relative;
    background: #0063B1;
    height: 30px;
    color:white;
}


.titleContent {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%)
}

.titleText {
    color: white;
    font-family: 'Segoe UI', Tahoma, sans-serif;
    font-weight: 400;
    font-size: 12px;
    display: inline;
    padding: 20px;
}

.titleText.noIcon {
    padding-left: 0;
}

.titleContent {
    padding: 6px 10px;
    display: inline;
}

.icon {
    position: absolute;
    top: 55%;
    transform: translate(0, -55%);
}
#notepad-app {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

#notepad-app-body > textarea {
    background: white;
    font-family: Consolas, sans-serif;
    font-size: 11pt;
    border: 1px solid #0078D7;
    padding: 5px;
    width: 100%;
    height: 90%;
    resize: none;
    top : -1px;
}
div.winbody {
    background: #F0F0F0;
    height: 100%;
    width: 100%;
    overflow: hidden;
    position: relative;
    left: 0;
    top: 0;
}

.dropdown {
    position: absolute;
    background-color: #F2F2F2;
    padding: 2px;
    width: 195px;
    height: auto;
    overflow: auto;
    box-shadow: 2px 2px 5px rgba(0,0,0,0.2);
    border: 1px solid lightgray;
}

.dropdown div{
    margin-right: 2rem;
    margin-left: 2rem;
}

.dropdown div button {
    color: black;
    text-decoration: none;
    font-family: 'Segoe UI', Tahoma, sans-serif;
    font-size: 9pt;
    display: block;
    padding: 4px 14px 4px 5px;
    transition: 0.1s;
    background-color: transparent;
    border: none;
}
.dropdown div hr {
    margin-top: 3px;
    margin-bottom: 3px;
    border-top: 2px solid #d2d2d2;

}

.dropdown div:hover {
    background-color: #91C9F7;
}

hr.dropdown-separator {
    display: block;
    margin: 0 auto;
}


div.menubar {
    background: #FFFFFF;
    font-family: 'Segoe UI', Tahoma, sans-serif;
    font-size: 12px;
    padding: 0 0;
    border-left:none;
    border-right:none;
    border-top:none;
    border-bottom:solid #F0F0F0 2px;
}

button.menubarItem {
    background: #FFFFFF;
    font-family: 'Segoe UI', Tahoma, sans-serif;
    font-size: 12px;
    border: 2px solid white;
    padding: 0 4px;
    transition: 0.1s;
}

button.menubarItem:hover, button.menubarItem:active {
    color: black;
    background: #91C9F7;
    border: 2px solid #91C9F7;
}
#chrome-app {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

#chrome-app-body > textarea {
    background: red;
    font-family: Consolas, sans-serif;
    font-size: 11pt;
    border: 1px solid #0078D7;
    padding: 5px;
    width: 100%;
    height: 90%;
    resize: none;
    top : -1px;
}


/* 
* Global CSS styles
*/
body{
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.64);
}
nav{
  width: 100%;
  padding: 10px 5px;
  border-bottom: 1px solid rgba(0,0,0,.2);
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: var(--main-bg);
  color: var(--main);
}

.tabs{
  position: relative;
  margin: 0px 5px;
  height: 23px;
  cursor: default;
  font-size: 12px;
}
.tab{
  -webkit-user-select: none;
  padding: 5px;
  padding-left: 15px;
  background-color: white;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.new-tab{
  -webkit-user-select: none;
  position: absolute;
  cursor: default;
  font-size: 18px;
  left: 105px;
  top: 2px;
  padding: 0px;
  border-radius: 50%;
  background-color: white;
  transition: .5s;
}
.new-tab:hover{
  background-color: rgb(232,234,237);
}


.icon{
  font-size: 2.5rem;
}

.search{
  width: 90%;
  border-radius: 15px;
  border: 2px solid transparent;
  background-color: rgb(241,243,244);
  outline: none;
  padding: 0px 40px;
  transition: background-color .1s;
}
.search:hover{
  background-color: rgb(232,234,237);
}
.search:focus{
  border: 2px solid rgb(179,206,251);
  background-color: transparent;
}
.search:focus::-webkit-input-placeholder {
    color: transparent;
}

nav > * {
    margin-right: 10px;
    padding: 2px;
  }
.hover-state {
    background: rgba(255, 255, 255, 0.1);
    transition: background 0.1s ease-in-out;
}

.taskbar {
    position: absolute;
    z-index: 9999;
    bottom: 0;
    background: #000;
    width: 100%;
    color: #fff;
}
.taskbar:before,
.taskbar:after {
    content: "";
    display: block;
    width: 100%;
    clear: both;
}
.taskbar .icon {
    margin : 1px;
    position: relative;
    display: inline-block;
    height: 50px;
    width: 50px;
    text-align: center;
    font-size: 1.3em;
    transition: background 0.2s ease-out;
}
.taskbar .iconAppImage {
    height: 27px;
    width: 27px;
}
.taskbar .icon:hover {
    background: rgba(255, 255, 255, 0.1);
    transition: background 0.1s ease-in-out;
}
.taskbar .icon:hover .fa-windows {
    color: #aaa;
}
.taskbar .icon.win.open {
    background: rgba(255, 255, 255, 0.1);
    transition: background 0.1s ease-in-out;
}
.taskbar .icon.minimized:after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: 5px;
    right: 5px;
    height: 2px;
    background: rgba(255, 255, 255, 0.5);
    transition: all 0.15s ease-out;
}
.taskbar .icon.minimized:hover:after,
.taskbar .icon.minimized.open:after {
    transition: all 0.15s ease-out;
    left: 0;
    right: 0;
}
.taskbar .icon .app-preview {
    overflow: hidden;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    bottom: 50px;
    width: 150px;
    height: 0;
    margin-left: -50px;
    border: 1px solid rgba(255, 255, 255, 0.4);
    border-bottom: 0;
    transition: all 0.2s ease-out;
    transition-delay: 0s;
}
.taskbar .icon .app-preview.preview-edge .content {
    overflow: hidden;
    position: relative;
}
.taskbar .icon .app-preview.preview-edge .content:after {
    content: "";
    display: block;
    position: absolute;
    z-index: 100;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: transparent;
}
.taskbar .icon .app-preview.preview-edge .content .edge-browser {
    background: transparent !important;
    width: 520px !important;
    height: 400px !important;
    transform: scale(0.3);
    transform-origin: top left;
}
.taskbar .icon.edge {
    color: #5a93e8;
}
.taskbar .icon.edge:hover .app-preview {
    visibility: visible;
    opacity: 1;
    height: 100px;
    transition: all 0.2s ease-out;
    transition-delay: 1s;
}
.taskbar .icon.edge.open .app-preview {
    visibility: hidden;
    opacity: 0;
}
.taskbar > .programs,
.taskbar > .tray {
    height: 50px;
    line-height: 50px;
}
.taskbar > .programs {
    float: left;
    width: 50%;
}
.taskbar > .tray {
    float: right;
    width: 50%;
    text-align: right;
}
.taskbar > .tray .peek {
    display: inline-block;
    width: 10px;
    height: 50px;
    margin-left: 10px;
    border-left: 1px solid rgba(255, 255, 255, 0.5);
}
.taskbar > .tray .peek:hover {
    background: rgba(255, 255, 255, 0.1);
    transition: background 0.1s ease-in-out;
}
.taskbar > .tray .all-icons {
    visibility: hidden;
    position: absolute;
    bottom: 50px;
    margin-left: -30px;
    padding: 5px;
    background: rgba(0, 0, 0, 0.5);
    text-align: left;
}
.taskbar > .tray .all-icons .fa, .taskbar > .tray .all-icons .fab{
    float: left;
    padding: 10px;
}
.taskbar > .tray .all-icons.open {
    visibility: visible;
}
.taskbar > .tray .ico {
    display: inline-block;
    font-size: 1.4em;
    width: 30px;
    height: 50px;
    text-align: center;
}
.taskbar > .tray .ico:hover {
    background: rgba(255, 255, 255, 0.1);
    transition: background 0.1s ease-in-out;
}
.taskbar > .tray .ico.time {
    position: relative;
    top: 6px;
    height: auto;
    width: 80px;
    line-height: 20px;
}
.taskbar > .tray .ico.time .clock,
.taskbar > .tray .ico.time .date {
    text-align: center;
    height: auto;
    font-size: 0.8em;
    line-height: 1em;
}
.taskbar > .tray .ico.time .date {
    margin-top: 5px;
}
.taskbar > .tray .ico.time:hover {
    background: transparent;
}


.ui-draggable-dragging iframe,
.ui-resizable-resizing iframe {
    pointer-events: none;
}

.ico.more-icons{
    font-size: 1.2em!important;
}

.main-ico{
    font-size: 1.3em!important;
    margin-right: 1rem;
}

.programsPowerMenu {
    overflow: hidden;
    overflow-y: auto;
    padding-left: 0;
    padding-right: 0;
    border: 0.1rem solid "black";
    position: "absolute";
    z-index: 1999;
}

.programsPowerMenu .item {
    font-size: 20px;
    padding: 2px 0 2px 10px;
}

.programsPowerMenu .item:hover {
    background: #333;
}

.programsPowerMenu .item .fa {
    background: #999;
    padding: 5px;
}

.programsPowerMenu .item span {
    margin-left: 10px;
    font-size: 12px;
    line-height: 30px;
    vertical-align: bottom;
}

.startmenu {
    overflow: hidden;
    opacity: 0;
    visibility: hidden;
    background: #171717;
    position: absolute;
    bottom: 50px;
    left: 0;
    height: 300px;
    width: 550px;
    padding-top: 5px;
    border-top: 1px solid rgba(255, 255, 255, 0.4);
    border-right: 1px solid rgba(255, 255, 255, 0.4);
}
.startmenu.open2 {
    transition: all 0.2s ease-in-out;
    height: 400px;
    opacity: 1;
    visibility: visible;
}
.startmenu.open2 .icons .bottom {
    transition: all 0.3s ease-out;
    opacity: 1;
}
.startmenu.open2 .programs .item {
    margin-bottom: 1px;
    transition: margin 0.5s ease-out;
}
.startmenu.open2 .tiles .tile {
    margin-top: 0px;
    transition: margin 0.5s ease-out;
}
.startmenu .title {
    margin: 6px 0 0 0;
    padding: 8px 0 4px 10px;
}
.startmenu .title:hover {
    background: #333;
}
.startmenu > .container-fluid {
    padding: 0;
    height: 100%;
}
.startmenu > .container-fluid .row {
    margin-right: 0;
}
.startmenu > .container-fluid .row,
.startmenu > .container-fluid .programs,
.startmenu > .container-fluid .tiles {
    height: inherit;
    position: relative;
}
.startmenu .iconAppImage{
    height: 25px;
    width: 25px;
    background-color: blue;
}
.startmenu .icons {
    position: absolute;
    z-index: 500;
    top: 0;
    bottom: 0;
    left: 15px;
    width: 50px;
    overflow: hidden;
    transition: all 0.1s ease-out;
}
.startmenu .icons.open2 {
    width: 200px;
    background: #333;
    transition: all 0.2s ease-out;
}
.startmenu .icons .icon {
    position: relative;
    line-height: 50px;
    width: 100%;
    text-align: left;
}

.startmenu .icons .icon .fa {
    margin-left: 17px;
}

.startmenu .icons .icon img {
    max-width: 25px;
    margin-left: -4px;
}

.startmenu .icons .icon > span {
    position: absolute;
    left: 60px;
    font-size: 0.6em;
    line-height: 50px;
    width: 100px;
    height: 50px;
    text-align: left;
}

.startmenu .icons .icon > span.start {
    text-transform: uppercase;
    font-size: 1em;
    line-height: 2.5em;
}

.startmenu .icons .icon.top {
    position: absolute;
    z-index: 100;
}

.startmenu .icons .bottom {
    position: relative;
    bottom: 0;
    height: 100%;
    opacity: 0;
}

.startmenu .icons .bottom .icon {
    position: relative;
    text-align: center;
}

.startmenu .icons .bottom .icon.power {
    bottom: 0;
}

.startmenu .icons .bottom .icon.settings {
    bottom: 50px;
}

.startmenu .icons .bottom .icon.file-explorer {
    bottom: 100px;
}

.startmenu .icons .bottom .icon.profile {
    bottom: 150px;
    padding: 0 17px;
}

.startmenu .icons .bottom .icon.profile img {
    display: inline;
    border-radius: 50%;
}

.startmenu .programs {
    overflow: hidden;
    overflow-y: auto;
    padding-left: 0;
    padding-right: 0;
}

.startmenu .programs .item {
    font-size: 20px;
    margin-bottom: 10px;
    padding: 2px 0 2px 10px;
}

.startmenu .programs .item:hover {
    background: #333;
}

.startmenu .programs .item .fa {
    background: #999;
    padding: 5px;
}

.startmenu .programs .item span {
    margin-left: 10px;
    font-size: 12px;
    line-height: 30px;
    vertical-align: bottom;
}

.startmenu .tiles {
    overflow: hidden;
    padding-right: 12px;
}

.startmenu .tiles:hover {
    overflow-y: auto;
    padding-right: 0;
}

.startmenu .tiles .title {
    padding-left: 0;
}

.startmenu .tiles .title:hover {
    background: transparent;
}

.startmenu .tiles .tile {
    position: relative;
    background: #333;
    padding: 20px;
    min-height: 105px;
    margin-bottom: 4px;
    margin-top: 30px;
    text-align: center;
}

.startmenu .tiles .iconSize-large{
    font-size: 3rem;
}
.startmenu .tiles .iconSize-medium{
    font-size: 2rem;
}

.startmenu .tiles .tile > span {
    position: absolute;
    bottom: 4px;
    left: 4px;
    right: 4px;
    text-align: left;
}

.startmenu .tiles .tile > span .fa {
    float: right;
    font-size: 14px;
}

.startmenu .tiles .tile.wide {
    width: 100%;
    clear: both;
}

.startmenu .tiles .tile.medium {
    width: 49%;
    float: left;
}

.startmenu .tiles .tile.medium:nth-child(odd) {
    margin-right: 4px;
}

.startmenu .tiles .tile.medium:nth-child(even) {
    float: right;
}

.programsWifiMenu {
    
    overflow: hidden;
    overflow-y: auto;
    padding-left: 0;
    padding-right: 0;
    border: 0.1rem solid "black";
    position: "absolute";
    z-index: 1999;
    width: 250px;
}

.programsWifiMenu .item {
    font-size: 20px;
    padding: 2px 0 2px 10px;
    font-weight: normal;
    font-family: "Segoe UI";
}

.programsWifiMenu .item:hover {
    background: #333;
}

.programsWifiMenu .item .fa {
    background: #999;
    padding: 5px;
}

.programsWifiMenu .item span {
    margin-left: 10px;
    font-size: 18px;
    line-height: 30px;
    vertical-align: bottom;
    font-weight: normal;
    font-family: "Segoe UI";
}
.programsWifiMenu .item p {
    margin-left: 10px;
    font-size: 12px;
    line-height: 30px;
    color:gray;
    vertical-align: bottom;
}


.programsWifiMenu button{
    background-color: rgb(0, 99, 177);
    border: none;
    margin-right: 20px;
    float: right;
    margin-bottom: 20px;
}
.hover-state {
    background: rgba(255, 255, 255, 0.1);
    transition: background 0.1s ease-in-out;
}

.desktop2 {
    position: absolute;
    z-index: 0;
    left: 0;
    top : 30px;
    width: 10%;
    color: #fff;
}
.desktop2:before,
.desktop2:after {
    content: "";
    display: block;
    width: 100%;
    clear: both;
}
.desktop2 .icon {
    margin : 2px;
    position: relative;
    display: inline-block;
    height: 90px;
    width: 90px;
    line-height: 20px;
    text-align: center;
    font-size: 2em;
    transition: background 0.2s ease-out;
}
.desktop2 .iconAppImage {
    height: 50px;
    width: 50px;
}
.desktop2 .textApp {
    font-size: 12px;
    text-shadow: 1px 1px #000000;
    font-family: 'Montserrat';
}
.desktop2 .icon:hover {
    background: rgba(255, 255, 255, 0.1);
    transition: background 0.1s ease-in-out;
}

.open {
    background: rgba(255, 255, 255, 0.2);
    transition: background 0.1s ease-in-out;
}
.desktop2 .icon:hover .fa-windows {
    color: #aaa;
}
.desktop2 .icon.win.open {
    background: rgba(255, 255, 255, 0.1);
    transition: background 0.1s ease-in-out;
}
.desktop2 .icon.minimized:after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: 5px;
    right: 5px;
    height: 2px;
    background: rgba(255, 255, 255, 0.5);
    transition: all 0.15s ease-out;
}
.desktop2 .icon.minimized:hover:after,
.desktop2 .icon.minimized.open:after {
    transition: all 0.15s ease-out;
    left: 0;
    right: 0;
}
.desktop2 .icon .app-preview {
    overflow: hidden;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    bottom: 50px;
    width: 150px;
    height: 0;
    margin-left: -50px;
    border: 1px solid rgba(255, 255, 255, 0.4);
    border-bottom: 0;
    transition: all 0.2s ease-out;
    transition-delay: 0s;
}
.desktop2 .icon .app-preview.preview-edge .content {
    overflow: hidden;
    position: relative;
}
.desktop2 .icon .app-preview.preview-edge .content:after {
    content: "";
    display: block;
    position: absolute;
    z-index: 100;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: transparent;
}
.desktop2 .icon .app-preview.preview-edge .content .edge-browser {
    background: transparent !important;
    width: 520px !important;
    height: 400px !important;
    transform: scale(0.3);
    transform-origin: top left;
}
.desktop2 .icon.edge {
    color: #5a93e8;
}
.desktop2 .icon.edge:hover .app-preview {
    visibility: visible;
    opacity: 1;
    height: 100px;
    transition: all 0.2s ease-out;
    transition-delay: 1s;
}
.desktop2 .icon.edge.open .app-preview {
    visibility: hidden;
    opacity: 0;
}
.desktop2 > .programs,
.desktop2 > .tray {
    height: 50px;
    line-height: 50px;
}
.desktop2 > .programs {
    float: left;
    width: 50%;
}
.desktop2 > .tray {
    float: right;
    width: 50%;
    text-align: right;
}
.desktop2 > .tray .peek {
    display: inline-block;
    width: 10px;
    height: 50px;
    margin-left: 10px;
    border-left: 1px solid rgba(255, 255, 255, 0.5);
}
.desktop2 > .tray .peek:hover {
    background: rgba(255, 255, 255, 0.1);
    transition: background 0.1s ease-in-out;
}
.desktop2 > .tray .all-icons {
    visibility: hidden;
    position: absolute;
    bottom: 50px;
    margin-left: -60px;
    padding: 5px;
    background: rgba(0, 0, 0, 0.5);
    text-align: left;
}
.desktop2 > .tray .all-icons .fa, .desktop2 > .tray .all-icons .fab{
    float: left;
    margin: 10px;
}
.desktop2 > .tray .all-icons.open {
    visibility: visible;
}
.desktop2 > .tray .ico {
    display: inline-block;
    font-size: 1.4em;
    width: 30px;
    height: 50px;
    text-align: center;
}
.desktop2 > .tray .ico:hover {
    background: rgba(255, 255, 255, 0.1);
    transition: background 0.1s ease-in-out;
}
.desktop2 > .tray .ico.time {
    position: relative;
    top: 6px;
    height: auto;
    width: 80px;
    line-height: 20px;
}
.desktop2 > .tray .ico.time .clock,
.desktop2 > .tray .ico.time .date {
    text-align: center;
    height: auto;
    font-size: 0.8em;
    line-height: 1em;
}
.desktop2 > .tray .ico.time .date {
    margin-top: 5px;
}
.desktop2 > .tray .ico.time:hover {
    background: transparent;
}


.ui-draggable-dragging iframe,
.ui-resizable-resizing iframe {
    pointer-events: none;
}

.ico.more-icons{
    font-size: 1.2em!important;
}

.main-ico{
    font-size: 1.3em!important;
    margin-right: 1rem;
}
#file-explorer-app {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

#file-explorer-body > textarea {
    background: red;
    font-family: Consolas, sans-serif;
    font-size: 11pt;
    border: 1px solid #0078D7;
    padding: 5px;
    width: 100%;
    height: 90%;
    resize: none;
    top : -1px;
}


/* 
* Global CSS styles
*/
body{
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.64);
}
nav{
  width: 100%;
  padding: 10px 5px;
  border-bottom: 1px solid rgba(0,0,0,.2);
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: var(--main-bg);
  color: var(--main);
}

.tabs{
  position: relative;
  margin: 0px 5px;
  height: 23px;
  cursor: default;
  font-size: 12px;
}
.tab{
  -webkit-user-select: none;
  padding: 5px;
  padding-left: 15px;
  background-color: white;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.new-tab{
  -webkit-user-select: none;
  position: absolute;
  cursor: default;
  font-size: 18px;
  left: 105px;
  top: 2px;
  padding: 0px;
  border-radius: 50%;
  background-color: white;
  transition: .5s;
}
.new-tab:hover{
  background-color: rgb(232,234,237);
}


.icon{
  font-size: 2.5rem;
}

.search-file-explorer{
  width: 90%;
  border: 2px solid transparent;
  background-color: rgb(241,243,244);
  outline: none;
  padding: 0px 10px;
  margin: 0px 2%;
  transition: background-color .1s;
}
.search-file-explorer:hover{
  background-color: rgb(232,234,237);
}
.search-file-explorer:focus{
  border: 2px solid rgb(179,206,251);
  background-color: transparent;
}
.search-file-explorer:focus::-webkit-input-placeholder {
    color: transparent;
}

nav > * {
    margin-right: 10px;
    padding: 2px;
  }

  .openingChrome {
      background: #FFFFFF;
    }

    .headerPage{
      padding: 7vh 10vw;
      display: flex;
      align-items: center;
    }

    .headerPage h1{
      margin:0px;
      font-family: 'Montserrat';
      font-weight: bold;
      font-size: 30px;
      color: #CB4B4B;
    }

    .headerPage img{
      height:10vh;
      position: absolute;
      right : 7vw;
    }


  .center{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
  
  .card {
    background: #fff;
    border-radius: 2px;
    display: flex;
    width: 60vw;
    height: 40vh;
    margin: 1rem;
    box-shadow: 2px 6px 12px rgba(0,0,0,0.16), 2px 6px 12px rgba(0,0,0,0.16);
  }

  .exercice{
    margin: auto; /* Important */ 
    text-align: center; 
    font-family: 'Montserrat';
    font-weight: bold;
    font-size: 9vh;
    color: #19355C;
  }

  .next{
    font-family: 'Montserrat';
    font-weight: bold;
    font-size: 5vh;
    background-color: #CB4B4B;
    color: #FFFFFF;
    border: none;
    border-radius: 59px;
    padding:20px;
    margin : 20px
  }

  .footer{
    position: absolute;
    right : 7vh;
    bottom:7vh;
  }

  .footer h1{
    color: grey;
  }


  .vertical-center {
    margin: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }


  .vertical-horizontal-center {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 70%;
    transform: translate(-50%, -50%);
  }
.desktopMockup {
    width: 100%;
    height: 86vh;
    background: url(/static/media/DesktopMockup.4268140a.png) center top no-repeat;
    background-size: 100vw 100vh;
}

.insideMockup {
    position: absolute;
    left: 14.2%;
    top: 20.8%;
    width: 71.9%;
    height: 72%;
    overflow: hidden;
}

.progress_bar {
    position: relative;
    display: block;
    margin-top: 2vh;
    margin-left: auto;
    margin-right: auto;
    width: 60%;
    height: 3vh;
    background-color: ghostwhite;
}

.progress_validate{
    height: 100%;
    background: purple;
    padding-left: 2px;
    padding-right: 2px;
    background-clip: content-box;
    cursor : pointer;
}

.progress_non_validate{
    height: 100%;
    padding-left: 2px;
    padding-right: 2px;
    background-color: gainsboro;
    background-clip: content-box;
    cursor : pointer;
}

.help-button {
    position: absolute;
    bottom: 1em;
    right: 1em;
    text-align: center;
    height: 15em;
    width: 15em;
    font-size: 14px;
    cursor: pointer;
    transform: scale(1);
    transition: all 200ms ease;
    z-index: 99999;
  }

  .help-button-shake{
    -webkit-animation: shake 0.5s;
            animation: shake 0.5s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
  }
  .help-button span {
    display: block;
    font-size: 2em;
    transform: scale(1);
    transition: transform 100ms ease;
  }
  .help-button:hover span,
  .expanded .help-button span,
  .expanded .help-button span {
    transform: scale(1.25);
  }

  .help-overlay {
      position: fixed;
      text-align: center;
      background: #fff;
      border-radius: 2px;
      bottom: 100px;
      right: 150px;
      width: 0%;
      height: 0%;
      opacity: 0.95;
      visibility: hidden;
      transition: opacity .55s, height .35s, width .35s, visibility .35s;
      overflow: auto;
        z-index: 9999;
  }
  
  .help-overlay.help-active {
    margin: auto; /* Important */ 
      opacity: .95;
      visibility: visible;
      height: 86%;
      width: 80%;
  }

  .speech-bubble-ds {
    background: #fff;
    border: 1px solid #a7a7a7;
    border-radius: 4px;
    box-shadow: 4px 4px 0 rgba(0, 0, 0, 0.2);
    font-size: 1.2rem;
    line-height: 1.3;
    margin: 0 auto 40px;
    padding: 15px;
  }
  
.leave-button {
    position: absolute;
    bottom: 1em;
    left: 1em;
    text-align: center;
    padding: 1%;
    font-size: 4vh;
    border-radius: 10%;
    border: 0 none;
    background: #C14F54;
    color: #fff;
    cursor: pointer;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    transform: scale(1);
    transition: all 200ms ease;
    font-family: 'Montserrat';
    font-weight: bold;
  }
  .leave-button span {
    display: block;
    font-size: 2em;
    transform: scale(1);
    transition: transform 100ms ease;
  }
  .leave-button:hover span,
  .expanded .leave-button span,
  .expanded .leave-button span {
    transform: scale(1.25);
  }

  .leave-overlay {
    position: fixed;
    text-align: center;
    vertical-align: middle;
    background: #fff;
    bottom: 50px;
    left: 50px;
    width: 0%;
    height: 0%;
    opacity: 0.95;
    visibility: hidden;
    transition: opacity .55s, height .35s, width .35s, visibility .35s;
    overflow: auto;
    z-index: 9999;
}

.leave-overlay.leave-active {
    opacity: .95;
    visibility: visible;
    height: 80%;
    width: 80%;
}



@-webkit-keyframes shake {
  0% { transform: translate(0px, 0px) rotate(0deg); }
  10% { transform: translate(0px, 0px) rotate(0deg); }
  20% { transform: translate(0px, 0px) rotate(0deg); }
  30% { transform: translate(0px, 0px) rotate(0deg); }
  40% { transform: translate(0px, 0px) rotate(0deg); }
  50% { transform: translate(0px, 0px) rotate(0deg); }
  60% { transform: translate(0px, -30px) rotate(0deg); }
  70% { transform: translate(0px, -30px) rotate(0deg); }
  80% { transform: translate(0px, -30px) rotate(0deg); }
  90% { transform: translate(0px, -30px) rotate(0deg); }
  100% { transform: translate(0px, -30px) rotate(0deg); }
}



@keyframes shake {
  0% { transform: translate(0px, 0px) rotate(0deg); }
  10% { transform: translate(0px, 0px) rotate(0deg); }
  20% { transform: translate(0px, 0px) rotate(0deg); }
  30% { transform: translate(0px, 0px) rotate(0deg); }
  40% { transform: translate(0px, 0px) rotate(0deg); }
  50% { transform: translate(0px, 0px) rotate(0deg); }
  60% { transform: translate(0px, -30px) rotate(0deg); }
  70% { transform: translate(0px, -30px) rotate(0deg); }
  80% { transform: translate(0px, -30px) rotate(0deg); }
  90% { transform: translate(0px, -30px) rotate(0deg); }
  100% { transform: translate(0px, -30px) rotate(0deg); }
}

.displayNone{
  display : none;
}


.exercice-help{
  margin: auto; /* Important */ 
  text-align: center; 
  font-family: 'Montserrat';
  font-weight: bold;
  font-size: 5vh;
  color: #19355C;
}

.image-help{
  max-height: 50vh;
}
