
.programsPowerMenu {
    overflow: hidden;
    overflow-y: auto;
    padding-left: 0;
    padding-right: 0;
    border: 0.1rem solid "black";
    position: "absolute";
    z-index: 1999;
}

.programsPowerMenu .item {
    font-size: 20px;
    padding: 2px 0 2px 10px;
}

.programsPowerMenu .item:hover {
    background: #333;
}

.programsPowerMenu .item .fa {
    background: #999;
    padding: 5px;
}

.programsPowerMenu .item span {
    margin-left: 10px;
    font-size: 12px;
    line-height: 30px;
    vertical-align: bottom;
}