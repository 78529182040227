.hover-state {
    background: rgba(255, 255, 255, 0.1);
    -webkit-transition: background 0.1s ease-in-out;
    transition: background 0.1s ease-in-out;
}

.taskbar {
    position: absolute;
    z-index: 9999;
    bottom: 0;
    background: #000;
    width: 100%;
    color: #fff;
}
.taskbar:before,
.taskbar:after {
    content: "";
    display: block;
    width: 100%;
    clear: both;
}
.taskbar .icon {
    margin : 1px;
    position: relative;
    display: inline-block;
    height: 50px;
    width: 50px;
    text-align: center;
    font-size: 1.3em;
    -webkit-transition: background 0.2s ease-out;
    transition: background 0.2s ease-out;
}
.taskbar .iconAppImage {
    height: 27px;
    width: 27px;
}
.taskbar .icon:hover {
    background: rgba(255, 255, 255, 0.1);
    -webkit-transition: background 0.1s ease-in-out;
    transition: background 0.1s ease-in-out;
}
.taskbar .icon:hover .fa-windows {
    color: #aaa;
}
.taskbar .icon.win.open {
    background: rgba(255, 255, 255, 0.1);
    -webkit-transition: background 0.1s ease-in-out;
    transition: background 0.1s ease-in-out;
}
.taskbar .icon.minimized:after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: 5px;
    right: 5px;
    height: 2px;
    background: rgba(255, 255, 255, 0.5);
    -webkit-transition: all 0.15s ease-out;
    transition: all 0.15s ease-out;
}
.taskbar .icon.minimized:hover:after,
.taskbar .icon.minimized.open:after {
    -webkit-transition: all 0.15s ease-out;
    transition: all 0.15s ease-out;
    left: 0;
    right: 0;
}
.taskbar .icon .app-preview {
    overflow: hidden;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    bottom: 50px;
    width: 150px;
    height: 0;
    margin-left: -50px;
    border: 1px solid rgba(255, 255, 255, 0.4);
    border-bottom: 0;
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
    -webkit-transition-delay: 0s;
    transition-delay: 0s;
}
.taskbar .icon .app-preview.preview-edge .content {
    overflow: hidden;
    position: relative;
}
.taskbar .icon .app-preview.preview-edge .content:after {
    content: "";
    display: block;
    position: absolute;
    z-index: 100;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: transparent;
}
.taskbar .icon .app-preview.preview-edge .content .edge-browser {
    background: transparent !important;
    width: 520px !important;
    height: 400px !important;
    -webkit-transform: scale(0.3);
    transform: scale(0.3);
    -webkit-transform-origin: top left;
    transform-origin: top left;
}
.taskbar .icon.edge {
    color: #5a93e8;
}
.taskbar .icon.edge:hover .app-preview {
    visibility: visible;
    opacity: 1;
    height: 100px;
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
    -webkit-transition-delay: 1s;
    transition-delay: 1s;
}
.taskbar .icon.edge.open .app-preview {
    visibility: hidden;
    opacity: 0;
}
.taskbar > .programs,
.taskbar > .tray {
    height: 50px;
    line-height: 50px;
}
.taskbar > .programs {
    float: left;
    width: 50%;
}
.taskbar > .tray {
    float: right;
    width: 50%;
    text-align: right;
}
.taskbar > .tray .peek {
    display: inline-block;
    width: 10px;
    height: 50px;
    margin-left: 10px;
    border-left: 1px solid rgba(255, 255, 255, 0.5);
}
.taskbar > .tray .peek:hover {
    background: rgba(255, 255, 255, 0.1);
    -webkit-transition: background 0.1s ease-in-out;
    transition: background 0.1s ease-in-out;
}
.taskbar > .tray .all-icons {
    visibility: hidden;
    position: absolute;
    bottom: 50px;
    margin-left: -30px;
    padding: 5px;
    background: rgba(0, 0, 0, 0.5);
    text-align: left;
}
.taskbar > .tray .all-icons .fa, .taskbar > .tray .all-icons .fab{
    float: left;
    padding: 10px;
}
.taskbar > .tray .all-icons.open {
    visibility: visible;
}
.taskbar > .tray .ico {
    display: inline-block;
    font-size: 1.4em;
    width: 30px;
    height: 50px;
    text-align: center;
}
.taskbar > .tray .ico:hover {
    background: rgba(255, 255, 255, 0.1);
    -webkit-transition: background 0.1s ease-in-out;
    transition: background 0.1s ease-in-out;
}
.taskbar > .tray .ico.time {
    position: relative;
    top: 6px;
    height: auto;
    width: 80px;
    line-height: 20px;
}
.taskbar > .tray .ico.time .clock,
.taskbar > .tray .ico.time .date {
    text-align: center;
    height: auto;
    font-size: 0.8em;
    line-height: 1em;
}
.taskbar > .tray .ico.time .date {
    margin-top: 5px;
}
.taskbar > .tray .ico.time:hover {
    background: transparent;
}


.ui-draggable-dragging iframe,
.ui-resizable-resizing iframe {
    pointer-events: none;
}

.ico.more-icons{
    font-size: 1.2em!important;
}

.main-ico{
    font-size: 1.3em!important;
    margin-right: 1rem;
}