#chrome-app {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

#chrome-app-body > textarea {
    background: red;
    font-family: Consolas, sans-serif;
    font-size: 11pt;
    border: 1px solid #0078D7;
    padding: 5px;
    width: 100%;
    height: 90%;
    resize: none;
    top : -1px;
}


/* 
* Global CSS styles
*/
@import url(https://fonts.googleapis.com/css?family=Roboto:300,500);
body{
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.64);
}
nav{
  width: 100%;
  padding: 10px 5px;
  border-bottom: 1px solid rgba(0,0,0,.2);
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: var(--main-bg);
  color: var(--main);
}

.tabs{
  position: relative;
  margin: 0px 5px;
  height: 23px;
  cursor: default;
  font-size: 12px;
}
.tab{
  -webkit-user-select: none;
  padding: 5px;
  padding-left: 15px;
  background-color: white;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.new-tab{
  -webkit-user-select: none;
  position: absolute;
  cursor: default;
  font-size: 18px;
  left: 105px;
  top: 2px;
  padding: 0px;
  border-radius: 50%;
  background-color: white;
  transition: .5s;
}
.new-tab:hover{
  background-color: rgb(232,234,237);
}


.icon{
  font-size: 2.5rem;
}

.search{
  width: 90%;
  border-radius: 15px;
  border: 2px solid transparent;
  background-color: rgb(241,243,244);
  outline: none;
  padding: 0px 40px;
  transition: background-color .1s;
}
.search:hover{
  background-color: rgb(232,234,237);
}
.search:focus{
  border: 2px solid rgb(179,206,251);
  background-color: transparent;
}
.search:focus::-webkit-input-placeholder {
    color: transparent;
}

nav > * {
    margin-right: 10px;
    padding: 2px;
  }