
  .openingChrome {
      background: #FFFFFF;
    }

    .headerPage{
      padding: 7vh 10vw;
      display: flex;
      align-items: center;
    }

    .headerPage h1{
      margin:0px;
      font-family: 'Montserrat';
      font-weight: bold;
      font-size: 30px;
      color: #CB4B4B;
    }

    .headerPage img{
      height:10vh;
      position: absolute;
      right : 7vw;
    }


  .center{
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
  }
  
  .card {
    background: #fff;
    border-radius: 2px;
    display: flex;
    width: 60vw;
    height: 40vh;
    margin: 1rem;
    box-shadow: 2px 6px 12px rgba(0,0,0,0.16), 2px 6px 12px rgba(0,0,0,0.16);
  }

  .exercice{
    margin: auto; /* Important */ 
    text-align: center; 
    font-family: 'Montserrat';
    font-weight: bold;
    font-size: 9vh;
    color: #19355C;
  }

  .next{
    font-family: 'Montserrat';
    font-weight: bold;
    font-size: 5vh;
    background-color: #CB4B4B;
    color: #FFFFFF;
    border: none;
    border-radius: 59px;
    padding:20px;
    margin : 20px
  }

  .footer{
    position: absolute;
    right : 7vh;
    bottom:7vh;
  }

  .footer h1{
    color: grey;
  }


  .vertical-center {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }


  .vertical-horizontal-center {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 70%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }