
.dropdown {
    position: absolute;
    background-color: #F2F2F2;
    padding: 2px;
    width: 195px;
    height: auto;
    overflow: auto;
    box-shadow: 2px 2px 5px rgba(0,0,0,0.2);
    border: 1px solid lightgray;
}

.dropdown div{
    margin-right: 2rem;
    margin-left: 2rem;
}

.dropdown div button {
    color: black;
    text-decoration: none;
    font-family: 'Segoe UI', Tahoma, sans-serif;
    font-size: 9pt;
    display: block;
    padding: 4px 14px 4px 5px;
    transition: 0.1s;
    background-color: transparent;
    border: none;
}
.dropdown div hr {
    margin-top: 3px;
    margin-bottom: 3px;
    border-top: 2px solid #d2d2d2;

}

.dropdown div:hover {
    background-color: #91C9F7;
}

hr.dropdown-separator {
    display: block;
    margin: 0 auto;
}


div.menubar {
    background: #FFFFFF;
    font-family: 'Segoe UI', Tahoma, sans-serif;
    font-size: 12px;
    padding: 0 0;
    border-left:none;
    border-right:none;
    border-top:none;
    border-bottom:solid #F0F0F0 2px;
}

button.menubarItem {
    background: #FFFFFF;
    font-family: 'Segoe UI', Tahoma, sans-serif;
    font-size: 12px;
    border: 2px solid white;
    padding: 0 4px;
    transition: 0.1s;
}

button.menubarItem:hover, button.menubarItem:active {
    color: black;
    background: #91C9F7;
    border: 2px solid #91C9F7;
}