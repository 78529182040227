body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-size: cover;
    overflow-y: hidden;
    margin: 0 0;
    user-select: none;
    -webkit-user-select: none;
    overflow: hidden;
    background-color: white;
    height: 100vh;
}
#root{
    height: 100%;
}

/* remember to define focus styles! */
:focus {
    outline: 0;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
    width: 12px;
}
::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.2);
}
::-webkit-scrollbar-thumb {
    background: #c3c3c3;
}
::-webkit-scrollbar-button {
    background-size: 100%;
    height: 10px;
    width: 10px;
    -webkit-box-shadow: inset 1px 1px 2px rgba(0, 0, 0, 0.2);
}