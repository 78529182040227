#notepad-app {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

#notepad-app-body > textarea {
    background: white;
    font-family: Consolas, sans-serif;
    font-size: 11pt;
    border: 1px solid #0078D7;
    padding: 5px;
    width: 100%;
    height: 90%;
    resize: none;
    top : -1px;
}